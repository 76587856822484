'use strict';

angular.module('enervexSalesappApp').controller('ApplicationsCtrl', function($scope, Application, _) {
	$scope.allApplications = [];
	$scope.offset = 0;
	$scope.limit = 50;
	function fetch() {
		Application.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(applications){
			if (applications.length > 0) {
				$scope.allApplications = $scope.allApplications.concat(applications);
				$scope.offset = $scope.offset + $scope.limit;
				fetch();
			} else {
				$scope.applications = _.sortBy($scope.allApplications, function(bomRule){
					return bomRule.category
				})
			}
		});
	}
	fetch();
	$scope.delete = function(bomRule) {
		Application.delete({
			id: bomRule._id
		});
		$scope.allApplications = _.filter($scope.allApplications, function(r){
			return r._id != bomRule._id
		})
		// $scope.applyFilter()
		$scope.applications = $scope.allApplications;
	}
	// $scope.applyFilter = function() {
	// 	var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

	// 	$scope.fanPhases = _.filter($scope.allApplications, function(rule){
	// 		if (nameReg && !(nameReg.test(rule.action) || nameReg.test(rule.filter) || (rule.product && nameReg.test(rule.product.name)) || nameReg.test(rule.legacyId) )) {
	// 			return false;
	// 		} else if ($scope.category && $scope.category != '' && $scope.category != rule.category) {
	// 			return false;
	// 		} else {
	// 			return true
	// 		}
	// 	})
	// }
});
